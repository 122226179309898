
video {
  max-width: 100%;
  max-height: 100%;
}
    .iframe-content {
        position: relative;
    }

    ::v-deep .el-breadcrumb {
        padding-bottom: 20px;
    }

    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;

        .back-btn {
            position: absolute;
            right: 0;
            top: -8px;
        }

        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;
            background-color: #fff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .course-ware {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;

                .course-ware-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 16px 0;
                    cursor: pointer;
                    border-radius: 10px;

                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                    }

                    .course-ware-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 107px;
                        height: 107px;
                    }

                    img.course-ware-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }

                    span.course-ware-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 107px;
                        text-align: center;
                    }
                }
            }
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            /*position: absolute;*/
            /*bottom: 20px;*/
            /*left: 0;*/
            /*right: 0;*/
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                display: flex;
                position: relative;
                padding: 6px;

                .new-module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    margin-right: 15px;
                }

                .module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 13px;
                }

                .item-cover {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }
                }

                .item-cover1 {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;
                    position: relative;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }

                    .show-img {
                        width: 100%;
                        height: 100%;

                        .img-box {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 50%
                        }
                    }
                }

                .show1 {
                    background: #61BCEB;
                }

                .show2 {
                    background: #3DCAC8;
                }

                .show3 {
                    background: #F0BC49;
                }

                .show4 {
                    background: #EC75A4;
                }

                .show5 {
                    background: #79ACDC;
                }

                .show6 {
                    background: #61BCEB;
                }

                .show7 {
                    background: #5AB9E9;
                }

                .courseware {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .textbook {
                    background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                }

                .lesson-plan {
                    background: linear-gradient(0deg, #53DED1, #2BB9BF);
                }

                .micro-lesson {
                    background: linear-gradient(0deg, #EE89B5, #EA5D90);
                }

                .animation {
                    background: linear-gradient(0deg, #F39E72, #EC6C6C);
                }

                .test-center {
                    background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                }

                .training-center {
                    background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                }

                .knowledge-points {
                    background: linear-gradient(0deg, #77C9F1, #47AEE4);
                }

                .material-library {
                    background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                }

                .case-library {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .related-copywriting {
                    background: linear-gradient(0deg, #8BC3EB, #628EC9);
                }

                .personal-info {
                    background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                }

                .del-btn {
                    width: 18px;
                    height: 18px;
                    background: #FF0000;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1;
                }

                .show-title {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                }

                .new-module-item:before {
                    content: "";
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    opacity: 0;
                }


                .new-module-item:hover:before {
                    width: 100%;
                    left: 0;
                    opacity: 0.5;
                }

                .new-module-item:hover .del-btn {
                    opacity: 1;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .single-data {
            width: 100%;
            height: 100%;
            background: #000;
          display: flex;
          overflow: hidden;
          .pdf-wrapper {
            ::v-deep .el-scrollbar__wrap {
              overflow-x: hidden;
            }
            ::v-deep .vue-office-pdf {
              .vue-office-pdf-wrapper {
                padding: 0!important;
              }
              canvas {
                width: 100%!important;
                height: initial!important;
              }
            }
          }
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            background-color: #fff;

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                display: flex;
                padding: 6px;

                .module-item {
                    display: inline-block;
                    min-width: 60px;
                    min-height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 15px;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
